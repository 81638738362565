var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("CInputCheckbox", {
        attrs: {
          label: "Nur importierte Gäste anzeigen",
          checked: _vm.showOnlyImportedGuests
        },
        on: {
          "update:checked": function($event) {
            _vm.showOnlyImportedGuests = $event
          }
        }
      }),
      _c("DataTable", {
        attrs: {
          items: _vm.users,
          "data-testid": "dataTable",
          fields: [
            {
              key: "firstName",
              label: _vm.$t("tables.user.firstName"),
              sorter: false,
              filter: false
            },
            {
              key: "lastName",
              label: _vm.$t("tables.user.lastName"),
              sorter: false,
              filter: false
            },
            {
              key: "displayName",
              label: _vm.$t("tables.user.displayName"),
              sorter: false,
              filter: false
            },
            {
              key: "userName",
              label: _vm.$t("tables.user.userName"),
              sorter: false,
              filter: false
            },
            {
              key: "createdAt",
              label: _vm.$t("tables.common.createdAt"),
              sorter: false,
              filter: false
            },
            {
              key: "lastLoginAt",
              label: _vm.$t("tables.common.lastLoginAt"),
              sorter: false,
              filter: false
            },
            {
              key: "actions",
              label: _vm.$t("global.actions"),
              sorter: false,
              filter: false
            }
          ],
          "table-filter-value": _vm.tableFilterValue,
          "sorter-value": _vm.sorterValue,
          loading: _vm.loading
        },
        on: {
          "update:tableFilterValue": function($event) {
            _vm.tableFilterValue = $event
          },
          "update:table-filter-value": function($event) {
            _vm.tableFilterValue = $event
          },
          "update:sorterValue": function($event) {
            _vm.sorterValue = $event
          },
          "update:sorter-value": function($event) {
            _vm.sorterValue = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "displayName",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "td",
                  [
                    _c(
                      "router-link",
                      {
                        directives: [
                          {
                            name: "c-tooltip",
                            rawName: "v-c-tooltip",
                            value: { appendToBody: true, content: item.id },
                            expression:
                              "{ appendToBody: true, content: item.id }"
                          }
                        ],
                        attrs: {
                          to: {
                            name: "AdminCustomerDetail",
                            params: { customerUserId: item.id }
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(item.displayName) + " ")]
                    )
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "userName",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "td",
                  [
                    _c(
                      "CBadge",
                      {
                        attrs: {
                          color: item.emailConfirmed ? "secondary " : "danger"
                        }
                      },
                      [_vm._v(" " + _vm._s(item.userName) + " ")]
                    )
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "createdAt",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "td",
                  [
                    _c("FormatedTimestamp", { attrs: { date: item.createdAt } })
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "lastLoginAt",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "td",
                  [
                    _c("FormatedTimestamp", {
                      attrs: { date: item.lastLoginAt }
                    })
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "actions",
            fn: function(ref) {
              var index = ref.index
              var item = ref.item
              return [
                _c(
                  "td",
                  { staticClass: "py-2" },
                  [
                    _c(
                      "CDropdown",
                      {
                        staticClass: "m-2",
                        attrs: {
                          color: "primary",
                          "toggler-text": "",
                          "data-testid": "actions"
                        }
                      },
                      [
                        _c(
                          "CDropdownItem",
                          {
                            staticClass: "text-warning",
                            attrs: { "data-testid": "login-as" },
                            on: {
                              click: function($event) {
                                return _vm.loginAs(item)
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("pages.admin.customerIndex.login")
                                ) +
                                " "
                            )
                          ]
                        ),
                        !item.emailConfirmed
                          ? _c(
                              "CDropdownItem",
                              {
                                staticClass: "text-warning",
                                attrs: { "data-testid": "confirm-email" },
                                on: {
                                  click: function($event) {
                                    return _vm.confirmEmail(item)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "pages.admin.AdminGuestIndex.confirmEmail"
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "CDropdownItem",
                          {
                            staticClass: "text-warning",
                            on: {
                              click: function($event) {
                                return _vm.openPasswordChangeModal(index)
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "pages.admin.customerIndex.changePassword"
                                  )
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "CDropdownItem",
                          {
                            staticClass: "text-danger",
                            on: {
                              click: function($event) {
                                return _vm.openDeleteModal(index)
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("pages.admin.customerIndex.delete")
                                ) +
                                " "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      }),
      _c("CPagination", {
        attrs: { pages: _vm.pages, activePage: _vm.currentPage },
        on: {
          "update:activePage": function($event) {
            _vm.currentPage = $event
          },
          "update:active-page": function($event) {
            _vm.currentPage = $event
          }
        }
      }),
      _c("AdminChangePassword", {
        attrs: { passwordChangeUserName: _vm.passwordChangeUserName },
        on: {
          success: function($event) {
            return _vm.closePasswordChangeModal()
          }
        }
      }),
      _c("DeleteConfirmationModal", {
        attrs: {
          show: _vm.showDeleteModal,
          message: _vm.$t("pages.AdminOwnerIndex.deleteMsg")
        },
        on: { delete: _vm.deleteUser, close: _vm.closeDeleteModal }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }